import React from 'react';

import { LoadingContainer, LoadingSpinner } from './styles';

const LoadingScreen = () => (
  <LoadingContainer>
    <LoadingSpinner animation="border" />
  </LoadingContainer>
);

export default LoadingScreen;
