import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Transition from '../../../../components/Transition/Transition';
import filterScenes from '../../../../lib/filterScenes';
import SceneList from './SceneList';
import SceneSelection from './SceneSelection';

const propTypes = {
  onConfirm: PropTypes.func.isRequired,
  scenario: PropTypes.shape().isRequired,
};

function Scenes({ onConfirm, scenario }) {
  const [addedScenes, setAddedScenes] = useState(scenario.scenes);

  const confirmSelection = () => {
    onConfirm({
      ...scenario,
      scenes: addedScenes,
    });
  };
  const addScene = useCallback((scene) => {
    setAddedScenes(currentScenes => [...currentScenes, scene]);
  }, []);
  const removeScene = useCallback((sceneIndex) => {
    setAddedScenes(currentScenes => currentScenes.slice(0, sceneIndex));
  }, []);
  const removeLastScene = useCallback(() => {
    removeScene(addedScenes.length - 1);
  }, [addedScenes, removeScene]);

  const possibleScenes = filterScenes(addedScenes, scenario.templateScenes);

  return (
    <Transition
      currentStep={possibleScenes.length > 0 ? `SceneSelection${addedScenes.length}` : 'SceneList'}
      startShown
      extraProps={{
        addedScenes,
        possibleScenes,
      }}
    >
      {(displayedStep, {
        possibleScenes: displayedPossibleScenes,
        addedScenes: displayedAddedScenes,
      }) => {
        if (displayedStep.startsWith('SceneSelection')) {
          return (
            <SceneSelection
              sceneIndex={displayedAddedScenes.length}
              onBack={removeLastScene}
              onSelect={addScene}
              scenes={displayedPossibleScenes}
            />
          );
        }

        return (
          <SceneList
            addedScenes={displayedAddedScenes}
            confirmSelection={confirmSelection}
            editFromIndex={removeScene}
          />
        );
      }}
    </Transition>
  );
}
Scenes.propTypes = propTypes;

export default Scenes;
