import { useCallback, useState } from 'react';

function useSwitchState() {
  const [state, setState] = useState();

  const switcher = useCallback((newState) => {
    setState((currentState) => {
      if (currentState !== newState) {
        return newState;
      }

      return undefined;
    });
  }, []);

  return [state, switcher];
}

export default useSwitchState;
