import Logger from '../../lib/Logger';
import initialStore from '../../lib/initialStore';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  RESET_CONFIG,
  SET_DEFAULT_FILTER,
  SET_LOCALE,
} from './ConfigActions';

const logger = Logger.getLogger('ConfigReducer');

function ConfigReducer(state = initialStore.config, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_CONFIG');
      return {
        ...initialStore.config,
      };

    case RESET_CONFIG:
      logger.trace('RESET_CONFIG');
      return {
        ...initialStore.config,
      };

    case SET_LOCALE:
      logger.trace('SET_LOCALE');
      return {
        ...state,
        locale: action.locale,
      };

    case SET_DEFAULT_FILTER:
      logger.trace('SET_DEFAULT_FILTER');
      return {
        ...state,
        defaultFilter: action.filter,
      };

    default:
      return state;
  }
}

export default ConfigReducer;
