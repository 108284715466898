import styled from 'styled-components';

import { DEFAULT_DARK } from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Kanit, sans-serif;
  font-size: 4vmin;
  background-color: ${DEFAULT_DARK};

  > * {
    margin: 2vmin;
  }
`;

export const Icon = styled.div`
  height: 25vmin;
  width: 25vmin;

  && > svg {
    height: 100%;
    width: 100%;
  }
`;

export const ReturnButton = styled.button`
  height: 13vmin;
  width: 44vmin;
  border: solid white 2px;
  border-radius: 15%/50%;
  background-color: transparent;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
`;

export const SorryText = styled.p`
  text-align: center;
  font-size: 1.3em;
`;
