import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'wouter';

import {
  formatDateToURL,
  generateWeek,
  getFirstAndLastDays,
  weekOffsetFromDate,
} from '../../lib/momentHelpers';
import {
  DateButton,
  DateRangeArrow,
  DateRangeSelector,
  Day,
  Month,
  NavContainer,
  NavContent,
  ReturnToday,
  WeekDay,
} from './styles';

const propTypes = {
  dayDateTime: PropTypes.shape().isRequired,
};

function NavigationBar({ dayDateTime }) {
  const { locale, formatMessage } = useIntl();
  const [weekModifier, setWeekModifier] = useState(weekOffsetFromDate(dayDateTime));
  const currentWeek = generateWeek(weekModifier);
  const today = DateTime.local();

  const increaseWeekModifier = useCallback(() => {
    setWeekModifier(currentMod => currentMod + 1);
  }, []);
  const decreaseWeekModifier = useCallback(() => {
    setWeekModifier(currentMod => currentMod - 1);
  }, []);

  return (
    <NavContainer>
      <DateRangeSelector>
        <DateRangeArrow
          icon={faChevronLeft}
          onClick={decreaseWeekModifier}
          disabled={weekModifier <= 0}
        />
        <FormattedMessage id="navbar_header_week" values={getFirstAndLastDays(currentWeek, locale)} />
        <DateRangeArrow icon={faChevronRight} onClick={increaseWeekModifier} />
      </DateRangeSelector>
      <NavContent>
        {currentWeek.map((day) => {
          const path = `/schedule/${formatDateToURL(day)}`;
          return (
            <Link to={path} key={formatDateToURL(day)}>
              <WeekDay>{day.weekdayShort.slice(0, 1)}</WeekDay>
              <DateButton active={day.toISODate() === dayDateTime.toISODate()}>
                <Day>{day.day}</Day>
                <Month>{day.monthShort}</Month>
              </DateButton>
            </Link>
          );
        })}
      </NavContent>
      {weekModifier !== 0 && (
        <ReturnToday to={`/schedule/${formatDateToURL(today)}`} onClick={() => setWeekModifier(0)}>
          {/* Using a FormattedMessage component results in the <a> node disappearing */}
          {formatMessage({ id: 'navbar_return_today' })}
        </ReturnToday>
      )}
    </NavContainer>
  );
}

NavigationBar.propTypes = propTypes;

export default NavigationBar;
