import Logger from '../../lib/Logger';
import initialStore from '../../lib/initialStore';
import { RESET_APP } from '../AppReducer/AppActions';
import {
  RESET_NOTIFICATION,
  SET_INTERACTIVE_NOTIFICATION,
  SET_NOTIFICATION,
} from './NotificationActions';

const logger = Logger.getLogger('NotificationReducer');

function NotificationReducer(state = initialStore.notification, action) {
  switch (action.type) {
    case RESET_APP:
      logger.trace('RESET_NOTIFICATION');
      return {
        ...initialStore.notification,
      };

    case RESET_NOTIFICATION:
      logger.trace('RESET_NOTIFICATION');
      return {
        ...initialStore.notification,
      };

    case SET_NOTIFICATION:
      return {
        ...state,
        messageKey: action.messageKey,
      };

    case SET_INTERACTIVE_NOTIFICATION:
      return {
        ...state,
        cancelCallback: action.cancelCallback,
        confirmCallback: action.confirmCallback,
        messageKey: action.messageKey,
      };

    default:
      return state;
  }
}

export default NotificationReducer;
