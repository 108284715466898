import { useQuery } from '@apollo/react-hooks';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'wouter';

import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { GET_ALL_SCENARIO_IN_RANGE } from '../../../lib/apollo/queries';
import formatCalenderToLocale from '../../../lib/formatCalenderToLocale';
import { isEarlierThanNow, isLaterThanNow } from '../../../lib/momentHelpers';
import { filterAvailable, filterUpcoming } from '../../../lib/scheduleFilters';
import sortScenarios from '../../../lib/sortScenarios';
import useInterval from '../../../lib/useInterval';
import {
  ButtonContainer,
  DayText,
  EditedByText,
  EmptyText,
  Logo,
  LogoContainer,
  PersonalizeButton,
  Slot,
  SortByButton,
  SortByContainer,
  SortByDropdown,
  SortByItem,
  StatusContainer,
  Time,
  TimeSlotInfo,
  TimeSlotsCol,
  TimeSlotsRow,
} from '../styles';
import PlayingAlert from './PlayingAlert/PlayingAlert';

function getInitialFilter(filterName) {
  switch (filterName) {
    case 'available':
      return () => filterAvailable;
    case 'upcoming':
      return () => filterUpcoming;
    default:
      return undefined;
  }
}

const propTypes = {
  dayDateTime: PropTypes.shape().isRequired,
  defaultFilter: PropTypes.string.isRequired,
  setDefaultFilter: PropTypes.func.isRequired,
};

function DayDisplay({ dayDateTime, defaultFilter, setDefaultFilter }) {
  const { formatMessage } = useIntl();
  const [, pushLocation] = useLocation();
  const {
    error,
    data,
    loading,
    refetch,
  } = useQuery(GET_ALL_SCENARIO_IN_RANGE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      end: DateTime.fromObject({
        day: dayDateTime.day,
        hour: 23,
        minute: 59,
        month: dayDateTime.month,
        second: 59,
        year: dayDateTime.year,
      }).toISO(),
      start: dayDateTime.toISO(),
    },
  });
  useInterval(() => refetch(), 10000);
  const [filterFunction, setFilterFunction] = useState(getInitialFilter(defaultFilter));

  const navigateCustomization = useCallback(
    time => () => {
      pushLocation(`${dayDateTime.toISODate()}/${time}`);
    },
    [pushLocation, dayDateTime],
  );
  const changeFilter = useCallback((filter) => {
    setFilterFunction(() => filter);

    switch (filter) {
      case filterAvailable:
        setDefaultFilter('available');
        break;
      case filterUpcoming:
        setDefaultFilter('upcoming');
        break;
      default:
        setDefaultFilter('none');
        break;
    }
  }, [setDefaultFilter]);
  const setFilterNone = useCallback(() => changeFilter(null), [changeFilter]);
  const setFilterAvailable = useCallback(
    () => changeFilter(filterAvailable),
    [changeFilter],
  );
  const setFilterUpcoming = useCallback(
    () => changeFilter(filterUpcoming),
    [changeFilter],
  );

  return (
    <>
      <PlayingAlert schedules={data ? data.getAllScenarioInRange : []} />
      <Row>
        <SortByContainer>
          <SortByDropdown>
            <SortByButton>
              <FormattedMessage id="schedule_filter" />
            </SortByButton>
            <DropdownMenu>
              <SortByItem active={filterFunction === null} onClick={setFilterNone}>
                <FormattedMessage id="schedule_filter_all" />
              </SortByItem>
              <SortByItem
                active={filterFunction === filterAvailable}
                onClick={setFilterAvailable}
              >
                <FormattedMessage id="schedule_filter_available" />
              </SortByItem>
              <SortByItem
                active={filterFunction === filterUpcoming}
                onClick={setFilterUpcoming}
              >
                <FormattedMessage id="schedule_filter_upcoming" />
              </SortByItem>
            </DropdownMenu>
          </SortByDropdown>
        </SortByContainer>
      </Row>
      <Row>
        <Col>
          <DayText>
            {formatCalenderToLocale(dayDateTime, formatMessage)}
          </DayText>
        </Col>
      </Row>
      <TimeSlotsRow>
        <TimeSlotsCol xs="12">
          {!data && loading && <LoadingScreen />}
          {(data && !error) && (() => {
            const { getAllScenarioInRange } = data;
            const filteredScenarios = filterFunction
              ? filterFunction(getAllScenarioInRange)
              : getAllScenarioInRange;

            if (filteredScenarios.length === 0) {
              return (
                <EmptyText>
                  <FormattedMessage id="schedule_empty" />
                </EmptyText>
              );
            }

            const slots = sortScenarios(filteredScenarios);

            return slots.map((timeSlot) => {
              const { author, startDate } = timeSlot;
              const locked = timeSlot.locked && isLaterThanNow(timeSlot.locked);
              const expired = isEarlierThanNow(
                DateTime.fromISO(timeSlot.startDate).minus({ minutes: 7 }),
              );
              const timeSlotDate = DateTime.fromISO(startDate);

              return (
                <Slot key={timeSlot.id} author={author} locked={locked || expired}>
                  <LogoContainer>
                    <Logo />
                  </LogoContainer>
                  <TimeSlotInfo>
                    <StatusContainer>
                      <Time>
                        {timeSlotDate.toFormat('H:mm')}
                      </Time>
                      <EditedByText>
                        {author && (
                          <FormattedMessage id="schedule_edited_by" values={{ user: author }} />
                        )}
                        {!author && expired && (
                          <FormattedMessage id="schedule_expired" />
                        )}
                        {!author && locked && (
                          <FormattedMessage id="schedule_reserved" />
                        )}
                        {!author && !locked && !expired && (
                          <FormattedMessage id="schedule_available" />
                        )}
                      </EditedByText>
                    </StatusContainer>
                    {!author && !locked && !expired && (
                      <ButtonContainer>
                        <PersonalizeButton onClick={navigateCustomization(timeSlotDate.toFormat('HH:mm'))}>
                          <FormattedMessage id="schedule_personalize" />
                        </PersonalizeButton>
                      </ButtonContainer>
                    )}
                  </TimeSlotInfo>
                </Slot>
              );
            });
          })()}
        </TimeSlotsCol>
      </TimeSlotsRow>
    </>
  );
}

DayDisplay.propTypes = propTypes;

export default DayDisplay;
