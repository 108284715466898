import { gql } from 'apollo-boost';

export const LOCK_SCENARIO = gql`
  mutation ($id: String!) {
    lockScenario(id: $id)
  }
`;

export const UNLOCK_SCENARIO = gql`
  mutation ($id: String!) {
    unlockScenario(id: $id)
  }
`;

export const UPDATE_SCENARIO = gql`
  mutation ($id: String!, $fields: ScenarioInput!) {
    updateScenario(id: $id, fields: $fields) {
      id
    }
  }
`;
