import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { CustomContainer } from '../../styles/styles';
import DayDisplay from './components/DayDisplay';
import { Background, Container } from './styles';

const propTypes = {
  defaultFilter: PropTypes.string.isRequired,
  params: PropTypes.shape({ day: PropTypes.string }),
  setDefaultFilter: PropTypes.func.isRequired,
};

const defaultProps = {
  params: {
    day: '',
  },
};

function Schedule({ defaultFilter, params: { day }, setDefaultFilter }) {
  const dayDateTime = (day ? DateTime.fromFormat(day, 'yyyy-LL-dd') : DateTime.local()).set({
    hour: 0,
    millisecond: 0,
    minute: 0,
    second: 0,
  });

  return (
    <Container>
      <NavigationBar dayDateTime={dayDateTime} />
      <CustomContainer>
        <Background />
        <DayDisplay
          dayDateTime={dayDateTime}
          defaultFilter={defaultFilter}
          setDefaultFilter={setDefaultFilter}
        />
      </CustomContainer>
    </Container>
  );
}

Schedule.propTypes = propTypes;
Schedule.defaultProps = defaultProps;

export default Schedule;
