import React, { useEffect, useState } from 'react';

import MessageScreen from '../MessageScreen/MessageScreen';
import HandleOrientation from './styles';

function BlockedOrientation() {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    const lastDimensions = {
      innerWidth: window ? window.innerWidth : 0,
    };

    const listener = ({ target }) => {
      const { innerWidth } = target;

      setIsFlipped(innerWidth < 1025 && innerWidth - lastDimensions.innerWidth > 200);
      lastDimensions.innerWidth = innerWidth;
    };
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);
  return (
    <HandleOrientation isFlipped={isFlipped}>
      <MessageScreen messageLabelKey="error_orientation" />
    </HandleOrientation>
  );
}

export default BlockedOrientation;
