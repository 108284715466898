import { DateTime } from 'luxon';

const firstDayFormat = {
  en: {
    differentMonth: 'LLLL d',
    sameMonth: 'LLLL d',
  },
  fr: {
    differentMonth: 'd LLLL',
    sameMonth: 'd',
  },
};
const lastDayFormat = {
  en: {
    differentMonth: 'LLLL d',
    sameMonth: 'd',
  },
  fr: {
    differentMonth: 'd LLLL',
    sameMonth: 'd LLLL',
  },
};

/**
 * Get number of weeks between now and a given date.
 *
 * @export
 * @param {DateTime} date
 * @returns
 */
export function weekOffsetFromDate(date) {
  const weeksDiff = Math.round(date.diffNow().as('weeks'));

  return weeksDiff;
}

/**
 * Generate moments for each day of a week.
 *
 * @export
 * @param {number} [weekOffset=0] Offset from current week (0 equals current week)
 * @returns
 */
export function generateWeek(weekOffset = 0) {
  const current = weekOffset > 0
    ? DateTime.local().plus({ weeks: Math.abs(weekOffset) })
    : DateTime.local().minus({ weeks: Math.abs(weekOffset) });

  return [
    DateTime.fromObject({
      weekNumber: current.weekNumber,
      weekYear: current.weekYear,
      weekday: 7,
    }).minus({ weeks: 1 }),
    ...[...Array(6)].map((_, i) => (
      DateTime.fromObject({
        weekNumber: current.weekNumber,
        weekYear: current.weekYear,
        weekday: i + 1,
      })
    )),
  ];
}

/**
 * Get strings for first and last days of the week.
 *
 * @export
 * @param {Array} week
 * @param {string} [locale='fr']
 * @returns
 */
export function getFirstAndLastDays(week, locale = 'fr') {
  const { [locale]: firstLocalFormat } = firstDayFormat;
  const { [locale]: lastLocalFormat } = lastDayFormat;

  if (week[0].month === week[6].month) {
    return {
      firstDay: week[0].toFormat(firstLocalFormat.sameMonth),
      lastDay: week[6].toFormat(lastLocalFormat.sameMonth),
    };
  }

  return {
    firstDay: week[0].toFormat(firstLocalFormat.differentMonth),
    lastDay: week[6].toFormat(lastLocalFormat.differentMonth),
  };
}

/**
 * Format a date into the format for URLs.
 *
 * @param {Object | string} date
 * @returns
 */
export function formatDateToURL(date) {
  if (typeof aMoment === 'string') {
    return DateTime.fromISO(date).toFormat('yyyy-LL-dd');
  }

  return date.toFormat('yyyy-LL-dd');
}

/**
 * Check if the supplied ISO date is set later than now.
 *
 * @export
 * @param {string} comparisonISO
 * @returns
 */
export function isLaterThanNow(comparisonISO) {
  const dateNow = DateTime.local();
  const comparisonDate = DateTime.fromISO(comparisonISO);

  return dateNow.diff(comparisonDate).as('milliseconds') < 0;
}

/**
 * Check if the supplied ISO date is set earlier than now.
 *
 * @export
 * @param {DateTime} comparisonDate
 * @returns
 */
export function isEarlierThanNow(comparisonDate) {
  const dateNow = DateTime.local();

  return dateNow.diff(comparisonDate).as('milliseconds') > 0;
}
