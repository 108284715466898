import { gql } from 'apollo-boost';

export const GET_ALL_SCENARIO_IN_RANGE = gql`
  query($start: String!, $end: String!) {
    getAllScenarioInRange(start: $start, end: $end) {
      id
      startDate
      author
      locked
      title {
        en
        fr
      }
    }
  }
`;

export const GET_SCENARIO = gql`
  query($id: String!) {
    getScenario(id: $id) {
      id
      endDate
      startDate
    }
  }
`;

export const GET_SCENARIO_WITH_START_DATE = gql`
query($startDate: String!) {
  getScenarioWithStartDate(startDate: $startDate) {
    id
    author
    endDate
    startDate
  }
}
`;

export const GET_ALL_SCENARIO_TEMPLATE = gql`
  query {
    getAllScenarioTemplate {
      id
      image
      title {
        en
        fr
      }
      scenes {
        id
        parentScenes {
          id
        }
        requiredScenes {
          id
        }
        index
        title {
          en
          fr
        }
        description {
          en
          fr
        }
        image
      }
    }
  }
`;

export const GET_ALLOW_CUSTOM_NAME = gql`
  query {
    getSettings {
      allowCustomName
    }
  }
`;
