import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { GET_ALLOW_CUSTOM_NAME } from '../../../../lib/apollo/queries';
import { validateAuthor, validateTitle } from '../../../../lib/signValidators';
import { CenteredCol } from '../../../../styles/styles';
import { SpacedLargeButton } from '../../styles';
import EditAllowed from './EditAllowed';
import EditNotAllowed from './EditNotAllowed';
import {
  Container,
  ContentCol,
  ContentRow,
  PageTitle,
} from './styles';

const propTypes = {
  onConfirm: PropTypes.func.isRequired,
  scenario: PropTypes.shape().isRequired,
};

function Sign({ onConfirm, scenario }) {
  const { locale, formatMessage } = useIntl();
  const [author, setAuthor] = useState(formatMessage({ id: 'generic_anonymous' }));
  const [title, setTitle] = useState(scenario.title[locale]);

  const { data } = useQuery(GET_ALLOW_CUSTOM_NAME, {
    fetchPolicy: 'network-only',
  });

  const onAuthorChange = useCallback(({ target: { value } }) => {
    setAuthor(value);
  }, []);
  const onTitleChange = useCallback(({ target: { value } }) => {
    setTitle(value);
  }, []);
  const signCompleted = () => {
    onConfirm({
      ...scenario,
      author,
      title: {
        en: title,
        fr: title,
      },
    });
  };

  return (
    <Container fluid>
      <Row>
        <CenteredCol>
          <PageTitle>
            {!data || !data.getSettings.allowCustomName
              ? <FormattedMessage id="customization_sign_title_no_edit" />
              : <FormattedMessage id="customization_sign_title" />
            }
          </PageTitle>
        </CenteredCol>
      </Row>
      <ContentRow style={{ overflow: 'auto' }}>
        <ContentCol>
          {!data || !data.getSettings.allowCustomName
            ? (
              <EditNotAllowed
                image={scenario.image}
                title={title}
              />
            )
            : (
              <EditAllowed
                author={author}
                defaultAuthor={formatMessage({ id: 'generic_anonymous' })}
                defaultTitle={scenario.title[locale]}
                image={scenario.image}
                title={title}
                onTitleChange={onTitleChange}
                onAuthorChange={onAuthorChange}
              />
            )
          }
          <Row>
            <CenteredCol>
              <SpacedLargeButton
                onClick={signCompleted}
                disabled={!validateAuthor(author).valid || !validateTitle(title).valid}
              >
                <FormattedMessage id="customization_complete" />
              </SpacedLargeButton>
            </CenteredCol>
          </Row>
        </ContentCol>
      </ContentRow>
    </Container>
  );
}

Sign.propTypes = propTypes;

export default Sign;
