import { CloudinaryProvider } from '@novom/cloudinary-image';
import { Settings } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';

import BlockedOrientation from '../components/BlockedOrientation/BlockedOrientation';
import i18n from '../lib/i18n/i18n';
import settings from '../lib/settings';
import Notification from '../modules/Notification/NotificationContainer';
import Routes from '../routes/Routes';

const propTypes = {
  config: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {
};

function App({ config: { locale } }) {
  Settings.defaultLocale = locale;

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={i18n[locale]}
    >
      <CloudinaryProvider
        cloudName={settings.cloudinary.cloudName}
        projectName={settings.cloudinary.folder}
      >
        <BlockedOrientation />
        <Notification />
        {Routes}
      </CloudinaryProvider>
    </IntlProvider>
  );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
