/**
 * @export
 * @param {Object} scenario
 * @param {Array} scenario.scenes
 * @returns
 */
function cleanScenario(scenario) {
  const { templateScenes: _, scenes, ...rest } = scenario;
  const cleanedScenario = {
    ...rest,
    scenes: scenes.map(({
      id,
      parentScenes,
      requiredScenes,
      ...scene
    }) => scene),
  };

  return cleanedScenario;
}

export default cleanScenario;
