import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import TabButton from '../../../../components/TabButton/TabButton';
import TabList from '../../../../components/TabList/TabList';
import useSwitchState from '../../../../lib/useSwitchState';
import { DarkButton, SpacedLargeButton } from '../../styles';
import {
  Container,
  PageTitle,
  SelectionBottomRow,
} from './styles';

const propTypes = {
  onBack: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  sceneIndex: PropTypes.number.isRequired,
  scenes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function SceneSelection({
  onBack,
  onSelect,
  sceneIndex,
  scenes,
}) {
  const [selectedScene, setSelectedScene] = useSwitchState();

  const confirmSelection = useCallback(() => {
    onSelect(selectedScene);
    setSelectedScene();
  }, [onSelect, selectedScene, setSelectedScene]);

  const backAndReset = useCallback(() => {
    onBack();
    setSelectedScene();
  }, [onBack, setSelectedScene]);

  return (
    <Container>
      <PageTitle>
        {sceneIndex === 0
          ? <FormattedMessage id="customization_next_scene_title_first" />
          : <FormattedMessage id="customization_next_scene_title_other" values={{ number: sceneIndex + 1 }} />
        }
      </PageTitle>
      <TabList>
        {scenes.map(scene => (
          <TabButton
            selected={selectedScene === scene}
            key={scene.id}
            scene={scene}
            onClick={() => setSelectedScene(scene)}
          />
        ))}
      </TabList>
      <SelectionBottomRow>
        {sceneIndex !== 0 && (
          <DarkButton
            onClick={backAndReset}
          >
            <FormattedMessage id="customization_scene_select_previous" />
          </DarkButton>
        )}
        <SpacedLargeButton
          onClick={confirmSelection}
          disabled={selectedScene === undefined}
        >
          <FormattedMessage id="customization_scene_select_confirm" />
        </SpacedLargeButton>
      </SelectionBottomRow>
    </Container>
  );
}

SceneSelection.propTypes = propTypes;

export default SceneSelection;
