import development from '../settings/development.json';
import production from '../settings/production.json';
import staging from '../settings/staging.json';
import testing from '../settings/testing.json';
import Environments from './environments';

export const env = process.env.REACT_APP_ENV || Environments.DEV;

const settings = {
  development,
  production,
  staging,
  testing,
}[env];

export default settings;
