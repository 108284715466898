import './index.css';

import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './app/AppContainer';
import configureStore from './lib/configureStore';
import settings from './lib/settings';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  uri: settings.graphql.endpointUrl,
});

render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
