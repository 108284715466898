import { Col, Container as BSContainer, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { DEFAULT_DARK, LIGHTER_DARK } from '../../../../styles/colors';

export const ImageContainer = styled.div`
  display: flex;
  height: 8em;
  width: 8em;
  justify-content: center;
  align-items: center;
  background-color: ${LIGHTER_DARK};
  border-radius: 50%;
  overflow: hidden;

  > * {
    object-fit: contain;
    height: inherit;
    width: inherit;
  }
`;

export const Container = styled(BSContainer)`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  width: 100%;
  text-align: center;
`;

export const PageTitle = styled.h1`
  text-align: center;
  font-weight: bold;
  color: ${DEFAULT_DARK};
`;

export const IncitationText = styled.h4`
  margin: 0.5rem 0;
  margin-top: 2.5rem;
`;

export const ScenarioTitle = styled.h3`
  text-align: center;
`;

export const ContentRow = styled(Row)`
  position: relative;
  flex: 1;
  overflow: hidden;
  background: linear-gradient(0deg, ${DEFAULT_DARK} 95%, rgba(255, 0, 0, 0) 0%);
`;

export const ContentCol = styled(Col)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export const Video = styled.video`
  width: 100%;
  outline: none;
`;

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0;
`;

export const VideoError = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
