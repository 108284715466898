import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import reduceScenarioCode from '../../../../lib/reduceScenarioCode';
import settings from '../../../../lib/settings';
import {
  AcceptButton,
  ButtonContainer,
  NotificationText,
  NotificationTitle,
} from '../../../Notification/styles';
import { Video, VideoContainer, VideoError } from './styles';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  scenario: PropTypes.shape().isRequired,
};

function ConfirmationModal({ open, onClose, scenario }) {
  const { cloudinary: { cloudName, folder } } = settings;
  const [videoCode, setVideoCode] = useState(undefined);
  const [videoError, setVideoError] = useState(false);

  useEffect(() => {
    if (open) {
      const scenarioCode = reduceScenarioCode(scenario);
      setVideoCode(scenarioCode);
    }
  }, [open, scenario]);

  return (
    <Modal centered show={open} onHide={onClose}>
      <Modal.Header>
        <NotificationTitle>
          <FormattedMessage id="customization_confirmation_title" />
        </NotificationTitle>
      </Modal.Header>
      <Modal.Body>
        <NotificationText>
          <FormattedMessage id="customization_confirmation_message" />
        </NotificationText>
        <VideoContainer>
          <Video
            src={`https://res.cloudinary.com/${cloudName}/video/upload/${folder}/videos/${videoCode}.mp4`}
            controls
            onError={() => setVideoError(true)}
          />
          {videoError && (
            <VideoError>
              <FormattedMessage id="customization_confirmation_video_error" />
            </VideoError>
          )}
        </VideoContainer>
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer>
          <AcceptButton onClick={onClose}>
            <FormattedMessage id="customization_confirmation_go_back" />
          </AcceptButton>
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.propTypes = propTypes;

export default ConfirmationModal;
