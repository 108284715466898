import styled from 'styled-components';

import { DEFAULT_DARK } from '../../../../styles/colors';

export const AlertContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 4;
  pointer-events: none;
`;

export const AlertBox = styled.div`
  display: flex;
  position: absolute;
  height: 4rem;
  width: 100%;
  bottom: ${({ show }) => (show ? '0' : '-4rem')};
  left: 0;
  padding: 0.7rem;
  flex-flow: row nowrap;
  align-items: center;
  transition: bottom 250ms;
  background-color: white;
  color: ${DEFAULT_DARK};
  pointer-events: all;
`;

export const IconContainer = styled.div`
  flex-shrink: 1;
  height: 2.5rem;
  width: 2.5rem;

  && > svg {
    height: 100%;
    width: 100%;
  }
`;

export const DismissButton = styled.div`
  flex-shrink: 1;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;

  && > svg {
    height: 100%;
    width: 100%;
  }
`;

export const TextContainer = styled.p`
  flex: 1;
  height: 2.6rem;
  margin-left: 1rem;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;
