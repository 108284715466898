import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import TabButton from '../../../../components/TabButton/TabButton';
import TabList from '../../../../components/TabList/TabList';
import { GET_ALL_SCENARIO_TEMPLATE } from '../../../../lib/apollo/queries';
import useSwitchState from '../../../../lib/useSwitchState';
import { SpacedLargeButton } from '../../styles';
import {
  Container,
  PageTitle,
} from './styles';

const propTypes = {
  onConfirm: PropTypes.func.isRequired,
  scenario: PropTypes.shape().isRequired,
};

function ScenarioSelection({ onConfirm, scenario }) {
  const { data, error, loading } = useQuery(GET_ALL_SCENARIO_TEMPLATE, {
    fetchPolicy: 'cache-and-network',
  });
  const [selectedScenario, setSelectedScenario] = useSwitchState();

  if (loading || error) return <LoadingScreen />;

  const { getAllScenarioTemplate } = data;

  const confirmScenario = () => {
    const { scenes, ...selectedScenarioRest } = getAllScenarioTemplate[selectedScenario];
    onConfirm({
      ...selectedScenarioRest,
      ...scenario,
      scenes: [],
      templateScenes: scenes,
    });
  };

  return (
    <Container>
      <PageTitle>
        <FormattedMessage id="customization_scenario_title" />
      </PageTitle>
      <TabList>
        {getAllScenarioTemplate.map((templateScenario, index) => (
          <TabButton
            selected={index === selectedScenario}
            key={templateScenario.id}
            scene={templateScenario}
            onClick={() => setSelectedScenario(index)}
          />
        ))}
      </TabList>
      <SpacedLargeButton disabled={selectedScenario === undefined} onClick={confirmScenario}>
        <FormattedMessage id="customization_step_next" />
      </SpacedLargeButton>
    </Container>
  );
}

ScenarioSelection.propTypes = propTypes;

export default ScenarioSelection;
