import styled from 'styled-components';

import logo from '../../assets/Logo_latuque.png';
import { DEFAULT_DARK } from '../../styles/colors';
import { CustomContainer, LargeButton } from '../../styles/styles';

export const Container = styled(CustomContainer)`
  height: 100%;
`;

export const Background = styled.div`
  position: absolute;
  top: 14%;
  left: 0;
  width: 100%;
  height: 74%;
  background: ${DEFAULT_DARK};
  transform: skewY(6deg);
`;

export const Logo = styled.img.attrs({
  src: logo,
})`
  object-fit: contain;
  width: 15rem;
  height: 15rem;
`;

export const LanguageButton = styled(LargeButton)`
  margin: 0.5rem;
`;
