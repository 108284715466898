import { Col, Container } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { DEFAULT_DARK, DISABLED_GREY, DISABLED_LIGHT } from './colors';

export const CustomContainer = styled(Container).attrs({
  fluid: true,
})`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  flex: 1;
  overflow: hidden;
  padding-top: 1.5rem;
`;

export const CenteredCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FullHeightCol = styled(CenteredCol)`
  height: 100%;
`;

const withBorderStyle = css`
  border: 1px solid black;
`;

export const LargeButtonStyle = css`
  min-height: 2em;
  border: none;
  background-color: white;
  border-radius: 0.8em;
  color: ${DEFAULT_DARK};
  font-size: 1.2em;
  font-weight: bold;
  width: 80%;
  ${({ outline }) => outline && withBorderStyle}
  transition: background 250ms, color 250ms;
  cursor: pointer;

  :disabled {
    color: ${DISABLED_GREY};
    background-color: ${DISABLED_LIGHT};
  }

  :focus {
    outline: none;
  }
`;

export const LargeButton = styled.button`
  ${LargeButtonStyle}
`;

export const PillStyle = css`
  height: 2.6em;
  padding: 0 1.3em;
  border: solid white 2px;
  border-radius: 1.45em;
  background-color: transparent;
  font-weight: bold;
  color: white;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

export const PillButton = styled.button`
  ${PillStyle}
`;
