import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TransitionEffect from './styles';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  extraProps: PropTypes.shape(),
  startShown: PropTypes.bool,
};

const defaultProps = {
  currentStep: '',
  extraProps: undefined,
  startShown: false,
};

function Transition({
  children,
  currentStep,
  extraProps,
  startShown,
}) {
  const [step, setStep] = useState(currentStep);
  const [inTransition, setInTransition] = useState(!startShown);
  const [passedProps, setPassedProps] = useState(extraProps || {});

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInTransition(false);
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (currentStep !== step) {
      setInTransition(true);
      timeout = setTimeout(() => {
        setPassedProps(extraProps);
        setStep(currentStep);
        timeout = setTimeout(() => {
          setInTransition(false);
        }, 150);
      }, 400);
    }

    return () => {
      if (currentStep === step) {
        clearTimeout(timeout);
      }
    };
  }, [currentStep, step, extraProps]);

  return (
    <TransitionEffect inTransition={inTransition}>
      {children(step, passedProps)}
    </TransitionEffect>
  );
}

Transition.propTypes = propTypes;
Transition.defaultProps = defaultProps;

export default React.memo(Transition);
