import { createStore } from 'redux';

import packageJson from '../../package.json';
import appReducer from '../reducers/AppReducer/AppReducer';
import initialStore from './initialStore';

export default function configureStore() {
  let savedStore = {};
  if ('localStorage' in window && window.localStorage !== null) {
    savedStore = JSON.parse(window.localStorage.getItem('la-tuque')) || {};
    if (savedStore.config && savedStore.config.version !== packageJson.version) {
      savedStore = {};
    }
  }

  const store = createStore(
    appReducer(),
    {
      ...initialStore,
      ...savedStore,
    },
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );

  store.subscribe(() => {
    const { notification, ...storeState } = store.getState();
    if ('localStorage' in window && window.localStorage !== null) {
      localStorage.setItem('la-tuque', JSON.stringify(storeState));
    }
  });

  return store;
}
