import { faDizzy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import {
  Container,
  Icon,
  ReturnButton,
  SorryText,
} from './styles';

function NotFound() {
  const [, push] = useLocation();

  return (
    <Container>
      <Icon>
        <FontAwesomeIcon icon={faDizzy} />
      </Icon>
      <SorryText>
        <FormattedMessage id="not_found_sorry" />
      </SorryText>
      <ReturnButton onClick={() => push('/', true)}>
        <FormattedMessage id="not_found_return" />
      </ReturnButton>
    </Container>
  );
}

export default NotFound;
