import { remove as removeDiacritics } from 'diacritics';

import censorList from './censorList';

/**
 * Check if a word matches the bad words
 * The word is cleaned (diacritics removed and numbers are replaced by letters) before regex check
 * @param {string} word The string you want to test for bad words
 * @return {boolean} true if is a bad word
 */
export function isBadWord(word) {
  const regex = new RegExp(`\\b${censorList.badWords.join('\\b|\\b')}\\b`);

  const cleanWord = removeDiacritics(word).replace(/0/g, 'o')
    .replace(/1/g, 'i')
    .replace(/3/g, 'e')
    .replace(/4/g, 'a')
    .replace(/5/g, 's')
    .replace(/7/g, 't')
    .replace(/8/g, 'b')
    .toLowerCase();

  return cleanWord.match(regex);
}

/**
 * Censor the word if it is innapropriate
 * @param {string} word The word to censor
 */
export function censor(word) {
  const { happyWords } = censorList;
  const replacement = happyWords[Math.floor(Math.random() * (happyWords.length))];

  return !isBadWord(word) ? word : replacement;
}
