import { connect } from 'react-redux';

import App from './App';

const mapStateToProps = state => ({
  config: state.config,
});

const AppContainer = connect(mapStateToProps)(App);

export default AppContainer;
