import { useMutation } from '@apollo/react-hooks';
import { DateTime, Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'wouter';

import Transition from '../../components/Transition/Transition';
import { LOCK_SCENARIO, UNLOCK_SCENARIO, UPDATE_SCENARIO } from '../../lib/apollo/mutations';
import cleanScenario from '../../lib/cleanScenario';
import useInterval from '../../lib/useInterval';
import ScenarioSelection from './components/ScenarioSelection/ScenarioSelection';
import Scenes from './components/SceneSelection/Scenes';
import ConfirmationModal from './components/Sign/ConfirmationModal';
import Sign from './components/Sign/Sign';
import {
  Container,
  ContentContainer,
  DateTimeText,
  HeaderBackground,
  HeaderContainer,
  ReturnButton,
  TimerContainer,
  Title,
} from './styles';

const propTypes = {
  params: PropTypes.shape().isRequired,
  serverScenario: PropTypes.shape().isRequired,
  setBackConfirm: PropTypes.func.isRequired,
  setFilterUpcoming: PropTypes.func.isRequired,
  setKickReason: PropTypes.func.isRequired,
};

const steps = [ScenarioSelection, Scenes, Sign];

function Customization({
  serverScenario,
  setFilterUpcoming,
  params: { day },
  setBackConfirm,
  setKickReason,
}) {
  const { formatMessage, locale } = useIntl();
  const [, push] = useLocation();
  const [emitLock] = useMutation(LOCK_SCENARIO);
  const [emitUnlock] = useMutation(UNLOCK_SCENARIO);
  const [emitUpdate] = useMutation(UPDATE_SCENARIO);
  const [step, setStep] = useState(0);
  const [timer, setTimer] = useState(Duration.fromObject({ minutes: 5 }));
  const [scenario, setScenario] = useState(serverScenario);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Callbacks
  const navigateBack = useCallback(() => {
    push(`/schedule/${day}`);
  }, [day, push]);

  const displayReturnConfirm = useCallback(() => {
    setBackConfirm(navigateBack, () => {});
  }, [setBackConfirm, navigateBack]);

  const decreaseStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const increaseStep = async (newScenario) => {
    setScenario(newScenario);

    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      await emitUpdate({
        variables: {
          fields: cleanScenario(newScenario),
          id: serverScenario.id,
        },
      });
      setShowConfirmation(true);
    }
  };

  // Kick timeout
  useInterval(() => {
    const newTime = timer.minus({ seconds: 1 });
    setTimer(newTime);
    if (Math.trunc(newTime.as('seconds')) === 0) {
      setKickReason('notification_time_up');
      navigateBack();
    }
  }, 1000);

  // Scenario lock
  useEffect(() => {
    let acquiredLock = false;

    const tryLock = async () => {
      const { data: { lockScenario } } = await emitLock({
        variables: {
          id: serverScenario.id,
        },
      });

      if (!lockScenario) {
        setKickReason('notification_edit_blocked');
        navigateBack();
      } else {
        acquiredLock = true;
      }
    };
    tryLock();

    return () => {
      if (acquiredLock) {
        emitUnlock({ variables: { id: serverScenario.id } });
      }
    };
  }, [serverScenario, emitLock, emitUnlock, navigateBack, setKickReason]);

  useEffect(() => {
    window.onbeforeunload = () => formatMessage({ id: 'notification_refresh' });

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [formatMessage]);

  const { startDate, title } = scenario;
  const date = DateTime.fromISO(startDate);

  return (
    <Container>
      <HeaderContainer>
        <HeaderBackground />
        <ReturnButton onClick={step > 0 ? decreaseStep : displayReturnConfirm}>
          <FormattedMessage id="customization_return" />
        </ReturnButton>
        <DateTimeText>
          <FormattedMessage
            id="generic_date_time"
            values={{
              day: date.day,
              month: date.monthLong,
              time: date.toFormat('H:mm'),
            }}
          />
        </DateTimeText>
        <Title>
          {title && title[locale]}
        </Title>
      </HeaderContainer>
      <ContentContainer>
        <Transition currentStep={step}>
          {(displayedStep) => {
            const StepComponent = steps[displayedStep];

            return (
              <StepComponent onConfirm={increaseStep} scenario={scenario} />
            );
          }}
        </Transition>
      </ContentContainer>
      <TimerContainer>
        <FormattedMessage id="customization_time_left" values={{ time: timer.toFormat('m:ss') }} />
      </TimerContainer>
      <ConfirmationModal
        open={showConfirmation}
        onClose={() => {
          setFilterUpcoming();
          setShowConfirmation(false);
          navigateBack();
        }}
        scenario={scenario}
      />
    </Container>
  );
}

Customization.propTypes = propTypes;

export default Customization;
