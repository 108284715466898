import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { FormattedMessage } from 'react-intl';

import {
  AcceptButton,
  ButtonContainer,
  CancelButton,
  NotificationText,
  NotificationTitle,
} from './styles';

const propTypes = {
  cancelCallback: PropTypes.func,
  confirmCallback: PropTypes.func,
  messageKey: PropTypes.string.isRequired,
  resetNotification: PropTypes.func.isRequired,
};

const defaultProps = {
  cancelCallback: undefined,
  confirmCallback: undefined,
};

function Notification({
  cancelCallback,
  confirmCallback,
  messageKey,
  resetNotification,
}) {
  const cancel = useCallback(() => {
    if (cancelCallback) cancelCallback();
    resetNotification();
  }, [cancelCallback, resetNotification]);

  const confirm = useCallback(() => {
    if (confirmCallback) confirmCallback();
    resetNotification();
  }, [confirmCallback, resetNotification]);

  return (
    <Modal show={!!messageKey} centered onHide={() => {}}>
      <ModalHeader>
        <NotificationTitle>
          <FormattedMessage id="notification_attention" />
        </NotificationTitle>
      </ModalHeader>
      <ModalBody>
        <NotificationText>
          {messageKey && <FormattedMessage id={messageKey} />}
        </NotificationText>
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          {cancelCallback && (
            <CancelButton onClick={cancel} variant="danger">
              <FormattedMessage id="notification_cancel" />
            </CancelButton>
          )}
          <AcceptButton onClick={confirm}>
            <FormattedMessage id="notification_accept" />
          </AcceptButton>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
