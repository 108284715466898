import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloudImage } from '@novom/cloudinary-image';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DescriptionContainer,
  ImageContainer,
  ItemTitle,
  PlusSymbol,
  SelectedBorder,
  TabContainer,
} from './styles';

const propTypes = {
  onClick: PropTypes.func,
  scene: PropTypes.shape(),
  selected: PropTypes.bool,
  usePlaceHolders: PropTypes.bool,
};

const defaultProps = {
  onClick: () => {},
  scene: {
    description: undefined,
    image: undefined,
    title: undefined,
  },
  selected: false,
  usePlaceHolders: false,
};

function TabButton({
  onClick,
  scene,
  usePlaceHolders,
  selected,
}) {
  const { locale } = useIntl();
  const { description, image, title } = scene;
  return (
    <TabContainer onClick={onClick} selected={selected}>
      <SelectedBorder selected={selected} />
      <ImageContainer>
        {image
          ? (
            <CloudImage
              publicId={image}
            />
          ) : (
            <PlusSymbol>
              <FontAwesomeIcon icon={faPlus} />
            </PlusSymbol>
          )
        }
      </ImageContainer>
      <DescriptionContainer>
        <ItemTitle>
          {title && title[locale]}
          {!title && usePlaceHolders && <FormattedMessage id="customization_next_scene_title" />}
        </ItemTitle>
        <br />
        {description && description[locale]}
        {!description && usePlaceHolders && <FormattedMessage id="customization_next_scene_description" />}
      </DescriptionContainer>
    </TabContainer>
  );
}

TabButton.propTypes = propTypes;
TabButton.defaultProps = defaultProps;

export default TabButton;
