/**
 * Sort all scenarios by startDate.
 *
 * @param {Array} scenarios
 * @param {Array<{ timeSlots: Array }>} data.getAllScheduleForDay
 * @returns
 */
function sortScenarios(scenarios) {
  return scenarios
    .sort((timeSlotA, timeSlotB) => {
      if (timeSlotA.startDate > timeSlotB.startDate) {
        return 1;
      }
      if (timeSlotA.startDate < timeSlotB.startDate) {
        return -1;
      }

      return 0;
    });
}

export default sortScenarios;
