import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Message,
  MessageScreenContainer,
} from './styles';

const propTypes = {
  messageLabelKey: PropTypes.string.isRequired,
};

function MessageScreen(props) {
  const { messageLabelKey } = props;

  return (
    <MessageScreenContainer>
      <Message><FormattedMessage id={messageLabelKey} /></Message>
    </MessageScreenContainer>
  );
}

MessageScreen.propTypes = propTypes;

export default MessageScreen;
