import { useQuery } from '@apollo/react-hooks';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'wouter';

import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { GET_SCENARIO_WITH_START_DATE } from '../../lib/apollo/queries';
import { isEarlierThanNow } from '../../lib/momentHelpers';
import { setDefaultFilter } from '../../reducers/ConfigReducer/ConfigActions';
import { setInteractiveNotification, setNotification } from '../../reducers/NotificationReducer/NotificationActions';
import Customization from './Customization';

const propTypes = {
  params: PropTypes.shape({
    day: PropTypes.string,
    time: PropTypes.string,
  }).isRequired,
  setKickReason: PropTypes.func.isRequired,
};

function CustomizationContainer(props) {
  const { params, setKickReason } = props;
  const { day, time } = params;
  const { data, loading, error } = useQuery(GET_SCENARIO_WITH_START_DATE, {
    fetchPolicy: 'network-only',
    variables: {
      startDate: DateTime.fromISO(`${day}T${time}`).toISO(),
    },
  });

  if (loading || error) return <LoadingScreen />;

  const { getScenarioWithStartDate } = data;

  if (
    !getScenarioWithStartDate
    || getScenarioWithStartDate.author
    || isEarlierThanNow(DateTime.fromISO(getScenarioWithStartDate.startDate).minus({ minutes: 7 }))
  ) {
    setKickReason('notification_edit_blocked');
    return <Redirect to={`/schedule/${day}`} />;
  }

  return <Customization serverScenario={getScenarioWithStartDate} {...props} />;
}

CustomizationContainer.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  setBackConfirm: (confirm, cancel) => dispatch(setInteractiveNotification('notification_go_back', confirm, cancel)),
  setFilterUpcoming: () => dispatch(setDefaultFilter('upcoming')),
  setKickReason: messageKey => dispatch(setNotification(messageKey)),
});

const connected = connect(undefined, mapDispatchToProps)(CustomizationContainer);

export default connected;
