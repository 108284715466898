import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import { formatDateToURL } from '../../lib/momentHelpers';
import { CenteredCol, FullHeightCol } from '../../styles/styles';
import {
  Background,
  Container,
  LanguageButton,
  Logo,
} from './styles';

const propTypes = {
  setLocale: PropTypes.func.isRequired,
};
const defaultProps = {};

function Welcome({ setLocale }) {
  const [, setLocation] = useLocation();

  const navigateToSchedule = useCallback(() => {
    setLocation(`schedule/${formatDateToURL(DateTime.local())}`);
  }, [setLocation]);

  const setEnglishAndNavigate = useCallback(() => {
    setLocale('en');
    navigateToSchedule();
  }, [navigateToSchedule, setLocale]);

  const setFrenchAndNavigate = useCallback(() => {
    setLocale('fr');
    navigateToSchedule();
  }, [navigateToSchedule, setLocale]);

  return (
    <Container>
      <Background />
      <Row>
        <FullHeightCol>
          <Logo alt="Logo centre d'amitié autochtone La Tuque" />
        </FullHeightCol>
      </Row>
      <Row>
        <FullHeightCol>
          <h1><FormattedMessage id="welcome_message" /></h1>
        </FullHeightCol>
      </Row>
      <Row>
        <CenteredCol xs="12">
          <LanguageButton onClick={setEnglishAndNavigate}>
            <FormattedMessage id="welcome_english" />
          </LanguageButton>
        </CenteredCol>
        <CenteredCol xs="12">
          <LanguageButton onClick={setFrenchAndNavigate}>
            <FormattedMessage id="welcome_french" />
          </LanguageButton>
        </CenteredCol>
      </Row>
    </Container>
  );
}

Welcome.propTypes = propTypes;
Welcome.defaultProps = defaultProps;

export default Welcome;
