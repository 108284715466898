import styled from 'styled-components';

import { DEFAULT_DARK, SALMON } from '../../styles/colors';
import { LargeButton, PillButton } from '../../styles/styles';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  font-family: Kanit, sans-serif;
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  > * {
    position: relative;
  }
`;

export const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(${DEFAULT_DARK}, ${DEFAULT_DARK});
  transform: skewY(-6deg);
  transform-origin: top left;
`;

export const ReturnButton = styled(PillButton)`
  width: 6.5rem;
  margin-bottom: 1rem;
  border: solid ${SALMON} 2px;
  color: ${SALMON};
  font-size: 0.9rem;
`;

export const DateTimeText = styled.p`
  font-size: 1.8em;
  font-weight: bold;
  color: white;
`;

export const Title = styled.p`
  height: 1.2em;
  font-size: 1.2em;
  color: ${SALMON};
  margin-bottom: 1.8rem;
`;

export const ContentContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const TimerContainer = styled.div`
  position: relative;
  display: flex;
  background-color: white;
  color: ${DEFAULT_DARK};
  justify-content: center;
  align-items: center;
`;

export const SpacedLargeButton = styled(LargeButton)`
  margin: 0 0.2rem 0.5rem 0.2rem;
`;

export const DarkButton = styled(SpacedLargeButton)`
  background-color: ${DEFAULT_DARK};
  color: white;
`;
