import { connect } from 'react-redux';

import { resetNotification } from '../../reducers/NotificationReducer/NotificationActions';
import Notification from './Notification';

const mapSateToProps = state => ({
  cancelCallback: state.notification.cancelCallback,
  confirmCallback: state.notification.confirmCallback,
  messageKey: state.notification.messageKey,
});

const mapDispatchToProps = dispatch => ({
  resetNotification: () => dispatch(resetNotification()),
});

const NotificationContainer = connect(mapSateToProps, mapDispatchToProps)(Notification);

export default NotificationContainer;
