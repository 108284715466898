import { Col, Dropdown, Row } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import styled from 'styled-components';

import logoLatuque from '../../assets/Logo_latuque.png';
import {
  ACCENT_RED,
  ACCENT_YELLOW,
  DEFAULT_DARK,
  DISABLED_GREY,
} from '../../styles/colors';
import { PillButton, PillStyle } from '../../styles/styles';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${DEFAULT_DARK};
  transform: skewY(-6deg);
  transform-origin: top right;
`;

export const Slot = styled.div`
  position: relative;
  padding: 0.8rem 0.5rem;
  margin: 0.4rem 0;
  border-radius: 1rem;
  text-decoration: none;
  background-color: white;
  border-bottom:
    0.4rem
    solid
    ${({ author, locked }) => {
    if (author) return ACCENT_RED;
    if (locked) return DISABLED_GREY;
    return ACCENT_YELLOW;
  }};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const TimeSlotsRow = styled(Row)`
  flex: 1;
  overflow: hidden;
`;

export const TimeSlotsCol = styled(Col)`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const LogoContainer = styled.div`
  position: relative;
  height: 5em;
  width: 5em;
  border-radius: 35%;
  background-color: ${ACCENT_RED};
`;

export const Logo = styled.img.attrs({
  src: logoLatuque,
})`
  padding: 0.2rem;
  position: relative;
  height: inherit;
  width: inherit;
  object-fit: contain;
  filter: invert();
`;

export const TimeSlotInfo = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  padding: 0 0 0 0.5rem;
  font-family: Kanit, sans-serif;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Time = styled.p`
  font-size: 1.7em;
  font-weight: bold;
  color: black;
`;

export const EditedByText = styled.p`
  font-size: 0.9em;
  color: #868695;
`;

export const PersonalizeButton = styled(PillButton)`
  padding: 0 0.6rem;
  border-color: black;
  font-size: 0.8em;
  text-transform: uppercase;
  color: black;
`;

export const SortByContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const SortByDropdown = styled(Dropdown)`

  &&.show > button {
    background-color: #393939;
    border-color: white;
  }
`;

export const SortByButton = styled(DropdownToggle)`
  ${PillStyle}
  text-transform: uppercase;
  font-family: Kanit, sans-serif;
  font-size: 0.8em;
  align-self: flex-end;

  &&&:focus {
    box-shadow: none;
    background-color: #393939;
    border-color: white;
  }

  :hover {
    border-color: white;
    background-color: #393939;
  }

  &&&&:active {
    box-shadow: none;
    border-color: white;
  }
`;

export const SortByItem = styled(DropdownItem)`

  &&.active {
    background-color: #393939;
  }

  :active {
    background-color: #393939;
  }
`;

export const DayText = styled.p`
  margin: 5px 10px;
  font-family: Kanit, sans-serif;
  font-size: 1.6em;
  font-weight: bold;
  color: white;
`;

export const EmptyText = styled.p`
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;
