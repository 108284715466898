import { isBadWord } from './censor';

/**
 * @typedef {Object} ValidatorReturn
 * @property {string} [reason]
 * @property {boolean} valid
 * @property {Object<string, any>} [values]
 */

/**
 * Check that author is valid.
 * To be valid it must be:
 *  * Longer than 0 characters
 *  * Smaller than 30 characters
 *  * Have no bad words
 *
 * @export
 * @param {string} author
 * @returns {ValidatorReturn}
 */
export function validateAuthor(author) {
  if (author.length <= 0) {
    return { reason: 'error_too_short', valid: false, values: { min: 1 } };
  }

  if (author.length > 20) {
    return { reason: 'error_too_long', valid: false, values: { max: 20 } };
  }

  if (isBadWord(author)) {
    return { reason: 'error_contains_bad_word', valid: false };
  }

  return { valid: true };
}

/**
 * Check that title is valid.
 * To be valid it must be:
 *  * Longer than 0 characters
 *  * Smaller than 120 characters
 *  * Have no bad words
 *
 * @export
 * @param {string} title
 * @returns {ValidatorReturn}
 */
export function validateTitle(title) {
  if (title.length <= 0) {
    return { reason: 'error_too_short', valid: false, values: { min: 1 } };
  }

  if (title.length > 40) {
    return { reason: 'error_too_long', valid: false, values: { max: 40 } };
  }

  if (isBadWord(title)) {
    return { reason: 'error_contains_bad_word', valid: false };
  }

  return { valid: true };
}
