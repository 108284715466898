import styled from 'styled-components';

import {
  ACCENT_YELLOW,
  DEFAULT_DARK,
  DISABLED_GREY,
  LIGHTER_DARK,
} from '../../styles/colors';

export const TabContainer = styled.button`
  display: flex;
  position: relative;
  width: ${({ selected }) => (selected ? '92%' : '85%')};
  height: 6em;
  flex-shrink: 0;
  align-items: center;
  background-color: ${DEFAULT_DARK};
  border-top-left-radius: 3em;
  border-bottom-left-radius: 3em;
  border: none;
  margin: 0 0 0.7rem auto;
  padding: 0;
  ${({ selected }) => (selected ? 'padding-right: 7%' : '')}
  transition: width 500ms, padding 500ms;
  overflow: hidden;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

export const PlusSymbol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${LIGHTER_DARK};
  height: 100%;
  width: 100%;
  color: white;

  && > svg {
    height: 45%;
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  position: relative;
  height: 6rem;
  width: 6rem;
  overflow: hidden;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${LIGHTER_DARK};

  > * {
    object-fit: contain;
    height: inherit;
    width: inherit;
  }
`;

export const DescriptionContainer = styled.p`
  flex: 1;
  max-height: 5.2rem;
  color: ${DISABLED_GREY};
  text-align: left;
  margin: 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemTitle = styled.span`
  color: white;
  font-size: 1.15em;
`;

export const SelectedBorder = styled.div`
  position: absolute;
  height: inherit;
  width: 100%;
  border: solid ${({ selected }) => (selected ? ACCENT_YELLOW : 'transparent')} 0.2rem;
  border-right: none;
  border-top-left-radius: 3em;
  border-bottom-left-radius: 3em;
  pointer-events: none;
  transition: border 500ms;
`;
