import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { filterUpcoming } from '../../../../lib/scheduleFilters';
import sortScenarios from '../../../../lib/sortScenarios';
import {
  AlertBox,
  AlertContainer,
  DismissButton,
  IconContainer,
  TextContainer,
} from './styles';

const propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function PlayingAlert({ schedules }) {
  const { locale } = useIntl();
  const [playingScenario, setPlayingScenario] = useState();

  useEffect(() => {
    let alertTimeout;

    const upcoming = filterUpcoming(schedules);

    if (upcoming.length > 0) {
      const first = sortScenarios(upcoming)[0];

      alertTimeout = setTimeout(() => {
        setPlayingScenario(first);
        setTimeout(() => {
          setPlayingScenario(undefined);
        }, 4000);
      }, DateTime.fromISO(first.startDate).diffNow().as('milliseconds'));
    }

    return () => {
      clearTimeout(alertTimeout);
    };
  }, [schedules]);

  return (
    <AlertContainer>
      <AlertBox show={!!playingScenario}>
        <IconContainer>
          <FontAwesomeIcon icon={faClock} />
        </IconContainer>
        <TextContainer>
          <b><FormattedMessage id="alert_just_started" /></b>
          <br />
          {playingScenario && (
            <FormattedMessage
              id="alert_text"
              values={{ author: playingScenario.author, title: playingScenario.title[locale] }}
            />
          )}
        </TextContainer>
        <DismissButton role="button" onClick={() => setPlayingScenario(undefined)}>
          <FontAwesomeIcon icon={faCheck} />
        </DismissButton>
      </AlertBox>
    </AlertContainer>
  );
}

PlayingAlert.propTypes = propTypes;

export default React.memo(PlayingAlert);
