/**
 * Format calender message to a localized string.
 *
 * @see https://moment.github.io/luxon/docs/manual/matrix.html
 *
 * @param {import('luxon').DateTime} dateTime
 * @param {Function} formatMessage
 * @param {string} locale
 */
function formatCalenderToLocale(dateTime, formatMessage) {
  const enString = dateTime.toRelativeCalendar({ locale: 'en' });
  const [first, second, third] = enString.split(' ');

  if (third) {
    if (third === 'ago') {
      return formatMessage({ id: `calender_${second}_ago` }, { number: first });
    }

    return formatMessage({ id: `calender_${third}_in` }, { number: second });
  }

  if (second) {
    if (first === 'last') {
      return formatMessage({ id: `calender_${second}_last` });
    }

    return formatMessage({ id: `calender_${second}_next` });
  }

  return formatMessage({ id: `calender_${first}` });
}

export default formatCalenderToLocale;
