import styled from 'styled-components';

import { DEFAULT_DARK } from '../../../../styles/colors';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const PageTitle = styled.h1`
  flex: 0;
  text-align: center;
  font-weight: bold;
  color: ${DEFAULT_DARK};
  margin-bottom: 0;
`;
