import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Link } from 'wouter';

import { DEFAULT_DARK } from '../../styles/colors';

export const NavContainer = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-flow: column nowrap;
  padding-top: 1rem;
  z-index: 1;
`;

export const NavContent = styled.nav`
  padding: 0.5em;
  display: flex;
  justify-content: space-evenly;

  a {
    color: white;
    text-decoration: none;
    outline: none;

    :hover {
      color: white;
    }
  }
`;

export const DateRangeSelector = styled.div`
  font-family: Kanit, sans-serif;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.8rem;
  font-size: 2em;
  font-weight: bold;
`;

export const DateRangeArrow = styled(FontAwesomeIcon).attrs({
  role: 'button',
})`
  ${({ disabled }) => disabled && 'visibility: hidden;'}
  color: black;
`;

export const DateButton = styled.div`
  width: 2.8rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  color: ${({ active }) => (active ? DEFAULT_DARK : 'white')};
  background: ${({ active }) => (active ? 'white' : DEFAULT_DARK)};
  border-radius: 0.8rem;
  padding: 1rem 0;
  line-height: 1;
`;

export const Day = styled.span`
  font-family: Dosis, serif;
  font-weight: bold;
  font-size: 1.3rem;
`;

export const Month = styled.span`
  text-transform: uppercase;
`;

export const WeekDay = styled.span`
  font-family: Kanit, serif;
  font-weight: bold;
  font-size: 1.15em;
  text-transform: capitalize;
`;

export const ReturnToday = styled(Link)`
  color: white;

  &:hover {
    color: #c4c4c4;
  }
`;
