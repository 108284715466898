import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Container, ListContainer, OverflowArrow } from './styles';

function checkOverflow(element) {
  const {
    clientHeight,
    scrollHeight,
    scrollTop,
  } = element;
  const scrollTopMax = scrollHeight - clientHeight;

  return clientHeight < scrollHeight && scrollTop + 20 < scrollTopMax;
}

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
const defaultProps = {
  children: undefined,
};

function TabList({ children }) {
  const ref = useRef();
  const [overflowing, setOverflowing] = useState(false);

  const onScroll = useCallback(({ target }) => {
    if (overflowing !== checkOverflow(target)) {
      setOverflowing(!overflowing);
    }
  }, [overflowing]);

  useEffect(() => {
    const { current } = ref;
    setOverflowing(checkOverflow(current));
  }, []);

  return (
    <Container>
      <ListContainer ref={ref} onScroll={onScroll}>
        {children}
      </ListContainer>
      <OverflowArrow show={overflowing}>
        <FontAwesomeIcon icon={faArrowDown} />
      </OverflowArrow>
    </Container>
  );
}

TabList.propTypes = propTypes;
TabList.defaultProps = defaultProps;

export default TabList;
