/* cSpell:language fr, en */

export default {
  alert_just_started: 'En lecture',
  alert_text: '{title}, par {author}',

  calender_days_ago: 'Il y a {number} jours',
  calender_days_in: 'Dans {number} jours',
  calender_month_last: 'Le mois passé',
  calender_month_next: 'Le mois prochain',
  calender_months_ago: 'Il y a {number} mois',
  calender_months_in: 'Dans {number} mois',
  calender_today: 'Aujourd’hui',
  calender_tomorrow: 'Demain',
  calender_year_last: 'L’année dernière',
  calender_year_next: 'L’année prochaine',
  calender_years_ago: 'Il y a {number} ans',
  calender_years_in: 'Dans {number} ans',
  calender_yesterday: 'Hier',

  customization_complete: 'Terminer',
  customization_confirmation_go_back: "Retourner à l'horaire",
  customization_confirmation_message: 'Votre personnalisation à été sauvegardée. Vous pouvez maintenant visualiser votre création!',
  customization_confirmation_title: 'Personnalisation terminée',
  customization_confirmation_video_error: 'Une erreur est survenue',
  customization_next_scene_description: 'Que va-t’il se passer en suite?',
  customization_next_scene_title_first: 'Première scène',
  customization_next_scene_title_other: 'Scène {number}',
  customization_return: 'Retour',
  customization_scenario_title: 'Le commencement',
  customization_scene_list_edit: 'Modifier',
  customization_scene_review: 'Révisez votre histoire',
  customization_scene_review_subtitle: '(Cliquez sur une scène pour modifier à partir de celle-ci)',
  customization_scene_select_confirm: 'Suivant',
  customization_scene_select_previous: 'Précédent',
  customization_scene_select_scene: 'Choisissez une scène',
  customization_sign_author: 'Utiliser une signature personnalisée',
  customization_sign_edit_incitation: 'Vous pouvez',
  customization_sign_story_title: 'Donner un nom unique à l’histoire',
  customization_sign_story_title_no_edit: 'Vous avez écrit',
  customization_sign_title: 'Personnalisation finale',
  customization_sign_title_no_edit: 'L’histoire est complétée',
  customization_step_next: 'Confirmer le choix',
  customization_time_left: 'Temps restant: {time}',
  customization_time_up: 'Le temps est écoulé! Vous allez être retourné à l’horaire.',

  error_contains_bad_word: 'Vous avez entré un mot interdit',
  error_orientation: 'Veuillez tourner votre appareil',
  error_too_long: 'Trop long: {max} caractère(s) maximum',
  error_too_short: 'Trop court: Au moins {min} caractère(s)',

  generic_and: 'et',
  generic_anonymous: 'Anonyme',
  generic_date_time: '{day} {month}, {time}',

  navbar_header_week: '{firstDay} au {lastDay}',
  navbar_return_today: 'Retourner à aujourd’hui',

  not_found_return: 'Retourner à l’accueil',
  not_found_sorry: 'Désolé, nous n’avons pas pu trouver cette page!',

  notification_accept: 'Accepter',
  notification_attention: 'Attention',
  notification_cancel: 'Annuler',
  notification_edit_blocked: 'Désolé, mais vous ne pouvez pas modifier cette plage',
  notification_go_back: `Êtes-vous sûre de vouloir retourner à l’horaire?
    Vous perdez les changements que vous avez fait et l’emplacement redeviendra disponible pour tous.`,
  notification_refresh: 'Attention: Si vous quitter sans retourner à l’horaire cette disponibilité restera verrouillée jusqu’à la fin du compteur',
  notification_time_up: 'Le temps est écoulé! Vous avez été redirigé à l’horaire.',

  schedule_available: 'Plage disponible',
  schedule_day_last_week: '{day} dernier',
  schedule_day_next_week: '{day}',
  schedule_day_other: '{day} le {date}',
  schedule_day_today: 'Aujourd’hui',
  schedule_day_tomorrow: 'Demain',
  schedule_day_yesterday: 'Hier',
  schedule_edited_by: 'Personnalisée par {user}',
  schedule_empty: 'Il n’y a aucune disponibilités à afficher',
  schedule_expired: 'Plage expirée',
  schedule_filter: 'Filtrer',
  schedule_filter_all: 'Tout',
  schedule_filter_available: 'Disponible',
  schedule_filter_upcoming: 'À venir',
  schedule_personalize: 'Personnaliser',
  schedule_reserved: 'Plage réservée',

  welcome_english: 'English',
  welcome_french: 'Français',
  welcome_message: 'Bienvenue',
};
