/* cSpell:language en */

export default {
  alert_just_started: 'Just Started',
  alert_text: '{title}, by {author}',

  calender_days_ago: '{number} Days ago',
  calender_days_in: 'In {number} Days',
  calender_month_last: 'Last Month',
  calender_month_next: 'Next Month',
  calender_months_ago: '{number} Months ago',
  calender_months_in: 'In {number} Months',
  calender_today: 'Today',
  calender_tomorrow: 'Tomorrow',
  calender_year_last: 'Last Year',
  calender_year_next: 'Next Year',
  calender_years_ago: '{number} Years ago',
  calender_years_in: 'In {number} Years',
  calender_yesterday: 'Yesterday',

  customization_complete: 'Complete',
  customization_confirmation_go_back: 'Back to schedule',
  customization_confirmation_message: 'Your customization has been saved. You now see your creation!',
  customization_confirmation_title: 'Customization complete',
  customization_confirmation_video_error: 'An error occurred',
  customization_next_scene_title_first: 'First Scene',
  customization_next_scene_title_other: 'Scene {number}',
  customization_return: 'Return',
  customization_scenario_title: 'The Beginning',
  customization_scene_list_edit: 'Edit',
  customization_scene_review: 'Review your story',
  customization_scene_review_subtitle: '(Click on a scene to edit starting from it)',
  customization_scene_select_confirm: 'Next',
  customization_scene_select_previous: 'Previous',
  customization_scene_select_scene: 'Select a scene',
  customization_sign_author: 'Use a custom pen name',
  customization_sign_edit_incitation: 'You can',
  customization_sign_story_title: 'Give the story an original name',
  customization_sign_story_title_no_edit: 'You wrote',
  customization_sign_title: 'Final Customization',
  customization_sign_title_no_edit: 'The story has been written',
  customization_step_next: 'Confirm choice',
  customization_time_left: 'Time left: {time}',
  customization_time_up: "Time's up! You will be taken back to the schedule.",

  error_contains_bad_word: 'You have entered a forbidden word',
  error_orientation: 'Please rotate your device',
  error_too_long: 'Too long: Maximum {max} character(s)',
  error_too_short: 'Too short: At least {min} character(s)',

  generic_and: 'and',
  generic_anonymous: 'Anonymous',
  generic_date_time: '{month} {day}, {time}',

  navbar_header_week: '{firstDay} to {lastDay}',
  navbar_return_today: 'Back to Today',

  not_found_return: 'Back to Home',
  not_found_sorry: "We're sorry, we could not find the page you were looking for!",

  notification_accept: 'Accept',
  notification_attention: 'Warning',
  notification_cancel: 'Cancel',
  notification_edit_blocked: 'Sorry but you cannot customize this slot',
  notification_go_back: 'Are you sure you want to go back to the schedule? You will lose your changes and this slot will be made available again.',
  notification_refresh: 'Warning: If you leave without returning to the schedule this slot will remain locked for the duration of the timer',
  notification_time_up: "Time's up! You have been taken back to the schedule.",

  schedule_available: 'Slot available',
  schedule_day_last_week: 'Last {day}',
  schedule_day_next_week: '{day}',
  schedule_day_other: '{day} the {date}',
  schedule_day_today: 'Today',
  schedule_day_tomorrow: 'Tomorrow',
  schedule_day_yesterday: 'Yesterday',
  schedule_edited_by: 'Customized by {user}',
  schedule_empty: 'There are no availabilities to show',
  schedule_expired: 'Slot expired',
  schedule_filter: 'Filter',
  schedule_filter_all: 'All',
  schedule_filter_available: 'Available',
  schedule_filter_upcoming: 'Upcoming',
  schedule_personalize: 'Customize',
  schedule_reserved: 'Slot reserved',

  welcome_english: 'English',
  welcome_french: 'Français',
  welcome_message: 'Welcome',
};
