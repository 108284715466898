import { combineReducers } from 'redux';

import ConfigReducer from '../ConfigReducer/ConfigReducer';
import NotificationReducer from '../NotificationReducer/NotificationReducer';

export default function appReducer() {
  const AppReducer = combineReducers({
    config: ConfigReducer,
    notification: NotificationReducer,
  });

  return AppReducer;
}
