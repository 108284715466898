import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
`;

export const LoadingSpinner = styled(Spinner)`
  height: 5rem;
  width: 5rem;
  color: white;
`;
