import { DateTime } from 'luxon';

import { isEarlierThanNow, isLaterThanNow } from './momentHelpers';

/**
 * Filter out all schedules that are deemed unavailable or expired.
 *
 * * To be unavailable it must be currently reserved or customized.
 * * To be expired it's startTime + 7 minutes must be larger than the current time.
 *
 * @export
 * @param {Array} schedules
 * @returns
 */
export function filterAvailable(schedules) {
  return schedules.filter((schedule) => {
    const { author, startDate, locked: lockedDate } = schedule;
    const locked = schedule.locked && isLaterThanNow(lockedDate);
    const expired = isEarlierThanNow(DateTime.fromISO(startDate).minus({ minutes: 7 }));

    return !author && !locked && !expired;
  });
}

/**
 * Filter out all schedules that are upcoming.
 *
 * To be upcoming it must:
 * * Have an author field
 * * Have a startTime that is smaller than now.
 *
 * @export
 * @param {Array} schedules
 * @returns
 */
export function filterUpcoming(schedules) {
  return schedules.filter((timeSlot) => {
    const { author, startDate } = timeSlot;
    const played = isEarlierThanNow(DateTime.fromISO(startDate));

    return author && !played;
  });
}

