/**
 * Reduce a scenario to it's BrightSign code.
 *
 * @param {{ id: string, scenes: { id: string, code: string }[] }} scenario
 * @returns
 */
function reduceScenarioCode(scenario) {
  return scenario.scenes.reduce((code, scene) => (
    code.concat(scene.index)
  ), '');
}

export default reduceScenarioCode;
