import { connect } from 'react-redux';

import { setDefaultFilter } from '../../reducers/ConfigReducer/ConfigActions';
import Schedule from './Schedule';

const mapStateToProps = state => ({
  defaultFilter: state.config.defaultFilter,
});

const mapDispatchToProps = dispatch => ({
  setDefaultFilter: filter => dispatch(setDefaultFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
