import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  overflow: hidden;
  margin: 0.5em 0;
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const OverflowArrow = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  font-size: 2rem;
  justify-content: center;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 100ms;
  z-index: 2;
  border: black;

  > svg {
    fill: none;
    stroke: black;
    stroke-width: 32px;
    stroke-dasharray: 8, 1;
    stroke-linejoin: round;
  }
`;
