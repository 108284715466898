/**
 * @typedef {Object} Scene
 *
 * @property {string} id
 * @property {Scene[]} parentScenes
 * @property {Scene[][]} requiredScenes
 */

/**
 * Filter scenes blocked by addedScenes
 *
 * @param {Scene[]} addedScenes
 * @param {Scene[]} allScenes
 * @returns {Scene[]}
 */
function filterScenes(addedScenes, allScenes) {
  const addedScenesId = addedScenes.map(scene => scene.id);
  const lastSelectedScene = addedScenes[addedScenes.length - 1];

  const withNextScenes = lastSelectedScene
    ? allScenes.filter(scene => (
      scene.parentScenes.some(parentScene => parentScene.id === lastSelectedScene.id)
    ))
    : allScenes.filter(scene => (
      scene.parentScenes.length === 0
    ));

  // Keeps scenes with all requiredScenes present in addedScenes
  const withPassingRequirementsScenes = withNextScenes.filter(scene => (
    // All requiredScenes sceneGroups must have at least one scene present in addedScenes
    scene.requiredScenes.every(sceneGroup => (
      sceneGroup.some(requiredScene => addedScenesId.includes(requiredScene.id))
    ))
  ));

  return withPassingRequirementsScenes;
}

export default filterScenes;
