import styled from 'styled-components';

const HandleOrientation = styled.div`
  display: ${({ isFlipped }) => (isFlipped ? 'flex' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export default HandleOrientation;
