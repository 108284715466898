import { CloudImage } from '@novom/cloudinary-image';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { CenteredCol } from '../../../../styles/styles';
import {
  ImageContainer,
  IncitationText,
  ScenarioTitle,
} from './styles';

const propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function EditNotAllowed({
  image,
  title,
}) {
  return (
    <Row>
      <CenteredCol xs="12">
        <ImageContainer>
          <CloudImage publicId={image} alt="scenario custom" />
        </ImageContainer>
      </CenteredCol>
      <CenteredCol xs="12">
        <IncitationText>
          <FormattedMessage id="customization_sign_story_title_no_edit" />
        </IncitationText>
      </CenteredCol>
      <CenteredCol xs="12">
        <ScenarioTitle>
          {title}
        </ScenarioTitle>
      </CenteredCol>
    </Row>
  );
}

EditNotAllowed.propTypes = propTypes;

export default EditNotAllowed;
