import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { validateAuthor, validateTitle } from '../../../../lib/signValidators';
import { CenteredCol } from '../../../../styles/styles';
import { IncitationText } from './styles';

const propTypes = {
  author: PropTypes.string.isRequired,
  defaultAuthor: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  onAuthorChange: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

function EditAllowed({
  author,
  defaultAuthor,
  defaultTitle,
  onTitleChange,
  onAuthorChange,
  title,
}) {
  const authorValidation = validateAuthor(author);
  const titleValidation = validateTitle(title);

  return (
    <Form>
      <Row>
        <CenteredCol>
          <IncitationText>
            <FormattedMessage id="customization_sign_edit_incitation" />
          </IncitationText>
        </CenteredCol>
      </Row>
      <Form.Row>
        <Form.Group as={Col} controlId="title-input">
          <Form.Label>
            <FormattedMessage id="customization_sign_story_title" />
          </Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={onTitleChange}
            onFocus={({ target }) => {
              if (title === defaultTitle) {
                onTitleChange({ target: { value: '' } });
              }
              target.scrollIntoView({ behaviour: 'smooth' });
            }}
            isInvalid={!titleValidation.valid}
          />
          <Form.Control.Feedback type="invalid">
            {!titleValidation.valid && (
              <FormattedMessage
                id={titleValidation.reason}
                values={titleValidation.values}
              />
            )}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="author-input">
          <Form.Label>
            <FormattedMessage id="customization_sign_author" />
          </Form.Label>
          <Form.Control
            type="text"
            value={author}
            onChange={onAuthorChange}
            onFocus={({ target }) => {
              if (author === defaultAuthor) {
                onAuthorChange({ target: { value: '' } });
              }
              target.scrollIntoView({ behaviour: 'smooth' });
            }}
            onKeyPress={({ key }) => key === 'Enter' && document.activeElement.blur()}
            isInvalid={!authorValidation.valid}
          />
          <Form.Control.Feedback type="invalid">
            {!authorValidation.valid && (
              <FormattedMessage
                id={authorValidation.reason}
                values={authorValidation.values}
              />
            )}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

EditAllowed.propTypes = propTypes;

export default EditAllowed;
