import React from 'react';
import { Route, Switch } from 'wouter';

import Customization from '../modules/Customization/CustomizationContainer';
import NotFound from '../modules/NotFound/NotFound';
import Schedule from '../modules/Schedule/ScheduleContainer';
import Welcome from '../modules/Welcome/WelcomeContainer';

const Routes = (
  <Switch>
    <Route path="/" component={Welcome} />
    <Route path="/schedule" component={Schedule} />
    <Route path="/schedule/:day" component={Schedule} />
    <Route path="/schedule/:day/:time" component={Customization} />
    <Route path="/:anything*" component={NotFound} />
  </Switch>
);

export default Routes;
