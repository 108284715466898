import { connect } from 'react-redux';

import { setLocale } from '../../reducers/ConfigReducer/ConfigActions';
import Welcome from './Welcome';

const mapDispatchToProps = dispatch => ({
  setLocale: locale => dispatch(setLocale(locale)),
});

const WelcomeContainer = connect(undefined, mapDispatchToProps)(Welcome);

export default WelcomeContainer;
