export const RESET_CONFIG = 'RESET_CONFIG';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_DEFAULT_FILTER = 'SET_DEFAULT_FILTER';

export function resetConfig() {
  return { type: RESET_CONFIG };
}

export function setLocale(locale) {
  return { locale, type: SET_LOCALE };
}

export function setDefaultFilter(filter) {
  return { filter, type: SET_DEFAULT_FILTER };
}
