export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_INTERACTIVE_NOTIFICATION = 'SET_INTERACTIVE_NOTIFICATION';

export function resetNotification() {
  return { type: RESET_NOTIFICATION };
}

export function setNotification(messageKey) {
  return { messageKey, type: SET_NOTIFICATION };
}

export function setInteractiveNotification(messageKey, confirmCallback, cancelCallback) {
  return {
    cancelCallback,
    confirmCallback,
    messageKey,
    type: SET_INTERACTIVE_NOTIFICATION,
  };
}
