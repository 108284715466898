import packageJson from '../../package.json';
import getBrowserLanguage from './getBrowserLanguage';
import { supportedLanguages } from './i18n/i18n';

const browserLanguage = getBrowserLanguage();

export default {
  config: {
    defaultFilter: 'available',
    locale: supportedLanguages.includes(browserLanguage) ? browserLanguage : 'fr',
    version: packageJson.version,
  },
  notification: {
    cancelCallback: undefined,
    confirmCallback: undefined,
    messageKey: '',
  },
};
