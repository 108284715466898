import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { DEFAULT_DARK } from '../../styles/colors';

export const NotificationTitle = styled.h3`
  color: black;
`;

export const NotificationText = styled.p`
  text-align: center;
  color: black;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
`;

export const CancelButton = styled(Button)`
  background-color: ${DEFAULT_DARK};
  border-color: white;

  &&&:focus {
    box-shadow: none;
    border-color: white;
    background-color: #393939;
  }

  :hover {
    border-color: white;
    background-color: #393939;
  }

  &&&&:active {
    box-shadow: none;
    border-color: white;
  }
`;

export const AcceptButton = styled(Button)`
  background-color: white;
  color: ${DEFAULT_DARK};
  border-color: ${DEFAULT_DARK};

  &&&:focus {
    box-shadow: none;
    color: ${DEFAULT_DARK};
    border-color: ${DEFAULT_DARK};
    background-color: #f2f2f2;
  }

  :hover {
    color: ${DEFAULT_DARK};
    border-color: ${DEFAULT_DARK};
    background-color: #f2f2f2;
  }

  &&&&:active {
    box-shadow: none;
    border-color: ${DEFAULT_DARK};
  }
`;
