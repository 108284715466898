import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TabButton from '../../../../components/TabButton/TabButton';
import TabList from '../../../../components/TabList/TabList';
import useSwitchState from '../../../../lib/useSwitchState';
import { DarkButton, SpacedLargeButton } from '../../styles';
import {
  Container,
  PageTitle,
  SelectionBottomRow,
  Subtitle,
} from './styles';

const propTypes = {
  addedScenes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  confirmSelection: PropTypes.func.isRequired,
  editFromIndex: PropTypes.func.isRequired,
};

function SceneList({
  addedScenes,
  confirmSelection,
  editFromIndex,
}) {
  const [selected, setSelected] = useSwitchState();

  return (
    <Container>
      <PageTitle>
        <FormattedMessage id="customization_scene_review" />
      </PageTitle>
      <Subtitle>
        <FormattedMessage id="customization_scene_review_subtitle" />
      </Subtitle>
      <TabList>
        {addedScenes.map((scene, index) => (
          <TabButton
            key={scene.id}
            scene={scene}
            selected={selected === index}
            onClick={() => setSelected(index)}
          />
        ))}
      </TabList>
      <SelectionBottomRow>
        {selected !== undefined && (
          <DarkButton onClick={() => editFromIndex(selected)}>
            <FormattedMessage id="customization_scene_list_edit" />
          </DarkButton>
        )}
        <SpacedLargeButton onClick={confirmSelection} disabled={addedScenes.length === 0}>
          <FormattedMessage id="customization_step_next" />
        </SpacedLargeButton>
      </SelectionBottomRow>
    </Container>
  );
}

SceneList.propTypes = propTypes;

export default SceneList;
